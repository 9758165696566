import { useFeedbackDialog } from "../components/FeedbackDialog";
import { DEFAULT_ERROR_MESSAGE } from "../utils/constants";
export var useCnhErrorHandler = function () {
    var feedbackDialog = useFeedbackDialog();
    return function (error) {
        var _a, _b, _c, _d;
        if ((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.length) {
            var errors = (_d = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.map(function (item) { return item.erro; });
            if (error.response.status >= 500) {
                feedbackDialog.error({
                    title: "Falha ao obter dados!",
                    descriptions: errors !== null && errors !== void 0 ? errors : [DEFAULT_ERROR_MESSAGE],
                });
            }
            else {
                feedbackDialog.warning({
                    title: "Falha ao obter dados!",
                    descriptions: errors !== null && errors !== void 0 ? errors : [DEFAULT_ERROR_MESSAGE],
                });
            }
        }
        else {
            feedbackDialog.error({
                title: "Aconteceu um erro!",
                descriptions: [DEFAULT_ERROR_MESSAGE],
            });
        }
    };
};
