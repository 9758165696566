var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Stack, Typography } from "@mui/material";
import { useWebgate } from "@xvia/webgate-connect";
import { isAxiosError } from "axios";
import { pdf } from "@react-pdf/renderer";
import { differenceInDays, parse as parseDate } from "date-fns";
import useLoading from "../../hooks/Loading";
import { downloadDocument, getStatusTaxa, getUserCnh, } from "../../services/api/cnh";
import CertidaoNegativaDocument from "../CertidaoNegativaDocument";
import { useFeedbackDialog } from "../FeedbackDialog";
import { useCnhErrorHandler } from "../../hooks/useCnhErrorHandler";
import { DEFAULT_ERROR_MESSAGE } from "../../utils/constants";
import { blobToBase64 } from "../../utils/file";
export default function DocummentEmission() {
    var _this = this;
    var _a = useWebgate(), accessToken = _a.accessToken, portalConnect = _a.portalConnect;
    var loading = useLoading();
    var feedbackDialog = useFeedbackDialog();
    var cnhErrorHandler = useCnhErrorHandler();
    var checkTaxaStatus = function (result) {
        if (isAxiosError(result)) {
            cnhErrorHandler(result);
        }
        else {
            var filteredResult = result.filter(Boolean);
            if (filteredResult.length === 0) {
                return false;
            }
            else {
                return filteredResult.every(function (item) {
                    var AFTER_PAYMENT_WORKING_DAYS = 3;
                    var today = new Date();
                    try {
                        var parsedPaymentDate = parseDate(item.dataPagamento, "yyyy-MM-dd HH:mm:ss", new Date());
                        var paymentDateDifference = differenceInDays(today, parsedPaymentDate);
                        return paymentDateDifference >= AFTER_PAYMENT_WORKING_DAYS;
                    }
                    catch (_a) {
                        return false;
                    }
                });
            }
        }
    };
    var handleDocumentEmissonButtonClick = function () { return __awaiter(_this, void 0, void 0, function () {
        var userChnResult, statusTaxaResult, document, pdfBlob, pdfBase64, downloadDocumentResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!accessToken) return [3 /*break*/, 9];
                    loading.show();
                    return [4 /*yield*/, getUserCnh(accessToken)];
                case 1:
                    userChnResult = _a.sent();
                    return [4 /*yield*/, getStatusTaxa(accessToken)];
                case 2:
                    statusTaxaResult = _a.sent();
                    if (!isAxiosError(userChnResult)) return [3 /*break*/, 3];
                    cnhErrorHandler(userChnResult);
                    return [3 /*break*/, 8];
                case 3:
                    if (!checkTaxaStatus(statusTaxaResult)) return [3 /*break*/, 7];
                    document = _jsx(CertidaoNegativaDocument, { data: userChnResult });
                    return [4 /*yield*/, pdf(document).toBlob()];
                case 4:
                    pdfBlob = _a.sent();
                    return [4 /*yield*/, blobToBase64(pdfBlob)];
                case 5:
                    pdfBase64 = _a.sent();
                    return [4 /*yield*/, downloadDocument(accessToken, {
                            base64: pdfBase64.replace("data:application/pdf;base64,", ""),
                            filename: "certidao-negativa-cnh-".concat(userChnResult.cpf, "-").concat(userChnResult.numeroCnh, ".pdf"),
                        })];
                case 6:
                    downloadDocumentResponse = _a.sent();
                    if (isAxiosError(downloadDocumentResponse)) {
                        feedbackDialog.error({
                            title: "Aconteceu um erro!",
                            descriptions: [DEFAULT_ERROR_MESSAGE],
                        });
                    }
                    else {
                        portalConnect === null || portalConnect === void 0 ? void 0 : portalConnect.download({
                            url: downloadDocumentResponse.url,
                            showShareDialog: true,
                            filename: "certidao-negativa-cnh-".concat(userChnResult.cpf, "-").concat(userChnResult.numeroCnh, ".pdf"),
                            headers: {
                                Authorization: "Bearer ".concat(accessToken),
                            },
                        });
                    }
                    return [3 /*break*/, 8];
                case 7:
                    feedbackDialog.warning({
                        title: "Aviso!",
                        descriptions: [
                            "É necessário pagar a taxa de emissão de certidão negativa da CNH.",
                        ],
                    });
                    _a.label = 8;
                case 8:
                    loading.hide();
                    _a.label = 9;
                case 9: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Stack, __assign({ sx: { p: 4, bgcolor: "white", borderRadius: "5px" }, spacing: 5 }, { children: [_jsx(Typography, __assign({ sx: {
                    color: "#000",
                    fontSize: "1rem",
                    fontWeight: 400,
                    lineHeight: "normal",
                } }, { children: "Para emitir a sua Certid\u00E3o Negativa da CNH clique no bot\u00E3o abaixo" })), _jsx(Button, __assign({ variant: "contained", onClick: handleDocumentEmissonButtonClick, sx: function (theme) {
                    var _a;
                    return (_a = {
                            borderRadius: "5px",
                            background: "#164194",
                            color: "#FFF",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            textTransform: "none",
                            py: 1.25,
                            px: 4,
                            alignSelf: "center",
                            width: "100%"
                        },
                        _a[theme.breakpoints.up("sm")] = {
                            alignSelf: "flex-end",
                            width: "fit-content",
                        },
                        _a);
                } }, { children: "Emitir Certid\u00E3o" }))] })));
}
