var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import { useWebgate } from "@xvia/webgate-connect";
import { BackButton } from "pigov-design-system";
export default function WelcomeSection() {
    var portalConnect = useWebgate().portalConnect;
    var handleBackButtonClick = function () { return window.history.back(); };
    return (_jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsxs(Stack, __assign({ spacing: 3 }, { children: [_jsx(BackButton, { webView: portalConnect === null || portalConnect === void 0 ? void 0 : portalConnect.isRunningInWebview(), text: "Voltar", onClick: handleBackButtonClick }), _jsx(Typography, __assign({ variant: "h1", sx: function (theme) { return ({
                            color: theme.palette.primary.main,
                            fontSize: "1.5rem",
                            fontWeight: 700,
                            lineHeight: "normal",
                        }); } }, { children: "Certid\u00E3o negativa da CNH" }))] })), _jsx(Typography, __assign({ sx: {
                    color: "#000",
                    fontSize: "1rem",
                    fontWeight: 400,
                    lineHeight: "150%",
                    letterSpacing: "0.024px",
                } }, { children: "Obtenha a certid\u00E3o negativa da sua Carteira Nacional de Habilita\u00E7\u00E3o (CNH)." }))] })));
}
