var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
export var getUserCnh = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, {
                renach: "PI316983470",
                registro: 5206357709,
                cpf: "04551105309",
                categoriaAtual: "B",
                ufEmissao: "PI",
                ufDominio: "PI",
                ufAtual: "PI",
                nome: "FRANCISCO HELIELSON MACEDO DE SOUSA",
                nomeMae: "IRISLENE SILVA MACEDO",
                nomePai: "FRANCISCO HELIO  SOARES DE SOUSA",
                dataNascimento: "1991-03-22",
                dataValidadeCnh: "2023-12-31",
                dataEmissao: "2016-03-28",
                dataPrimeiraHabilitacao: "2011-05-17",
                numeroCnh: 1239582219,
                tipoDocumento: 0,
                numeroDocumento: "3056446",
                orgaoExpedidor: "SSP",
                ufOrgaoExpedidor: "PI",
                bloqueioCnhApreendida: false,
                bloqueioDireto: false,
                bloqueioBca: false,
                cnhVencida: true,
                celular: "(86)99949-3582",
                logradouro: "RUA ARTUR SOARES FEITOSA",
                numeroLogradouro: "909",
                bairro: "909",
                cep: 64049840,
                complemento: "PROX AO COND",
                email: "ARNALDOFOX@HOTMAIL.COM",
                municipio: "TERESINA",
                municipioCod: 1219,
                uf: "PI",
                servicos: [
                    {
                        codigoTaxa: 2,
                        descricaoTaxa: "Renovação de CNH",
                        observacao: null,
                        situacaoTaxa: "Em uso",
                        valorPago: 111.11,
                        dataPagamento: "2023-09-28 00:00:00",
                        renach: null,
                    },
                ],
                permiteMudancaParaCnhPermanente: false,
                sexo: "MASCULINO",
                nacionalidade: "BRASILEIRO",
                municipioNascimento: "TERESINA",
                municipioNascimentoCod: 1219,
            }];
    });
}); };
/*enum FeeCode {
  CERTIDAO_NEGATIVA_CNH = 5,
}*/
export var getStatusTaxa = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, [
                {
                    cpf: "04551105309",
                    codigoTaxa: 5,
                    descricaoTaxa: "CERTIDÃO NEGATIVA DE CNH",
                    situacaoTaxa: "Paga",
                    valor: 36,
                    dataPagamento: "2023-11-10 00:00:00",
                    ativo: false,
                },
            ]];
    });
}); };
export var downloadDocument = function (token, _a) {
    var base64 = _a.base64, filename = _a.filename;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            return [2 /*return*/, {
                    url: "https://demo-app-obter-certidao-negativa-cnh.demo.xvia.com.br/certidao-negativa-cnh-04551105309-1239582219_2.pdf",
                }];
        });
    });
};
