var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Switch, Route } from "react-router-dom";
import { useMemo } from "react";
import { Routes } from "./routes";
export var RouterManager = function () {
    var routes = useMemo(function () {
        return Object.keys(Routes).map(function (path) {
            var RouteComponent = Routes[path];
            return (_jsx(Route, __assign({ path: path }, { children: _jsx(RouteComponent, {}) }), path));
        });
    }, []);
    return _jsx(Switch, { children: routes });
};
