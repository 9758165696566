var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Divider } from "@mui/material";
import WelcomeSection from "../../components/WelcomeSection";
import DocummentEmission from "../../components/DocummentEmission";
export default function Home() {
    return (_jsxs(Container, __assign({ sx: { py: 6 } }, { children: [_jsx(WelcomeSection, {}), _jsx(Divider, { sx: { borderColor: "rgba(115, 115, 115, 0.50)", my: 4 } }), _jsx(DocummentEmission, {})] })));
}
