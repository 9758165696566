var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress } from "@mui/material";
import LoadingContext from "../../contexts/Loading";
import "./style.scss";
export default function Loading() {
    return (_jsx(LoadingContext.Consumer, { children: function (_a) {
            var isLoading = _a.isLoading;
            return (_jsxs("div", __assign({ className: "loading-modal", "custom-loading": isLoading.toString() }, { children: [_jsx("div", { className: "loading-modal__overlay", onClick: function (e) { return e.stopPropagation(); } }), _jsxs("div", __assign({ className: "loading-modal__content" }, { children: [_jsx("div", __assign({ className: "loading-modal__content__icon" }, { children: _jsx(CircularProgress, {}) })), _jsx("div", __assign({ className: "loading-modal__content__text" }, { children: _jsx("p", { children: "Carregando..." }) }))] }))] })));
        } }));
}
