var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import { useState } from "react";
import Loading from "./components/Loading";
import LoadingContext from "./contexts/Loading";
import { RouterManager } from "./routes/RouterManager";
import FeedbackDialog from "./components/FeedbackDialog";
export var App = function () {
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    return (_jsx(LoadingContext.Provider, __assign({ value: {
            isLoading: isLoading,
            setIsLoading: setIsLoading,
        } }, { children: _jsxs(Stack, __assign({ direction: "column" }, { children: [_jsx(RouterManager, {}), _jsx(Loading, {}), _jsx(FeedbackDialog, {})] })) })));
};
