import { useContext } from "react";
import LoadingContext from "../contexts/Loading";
var useLoading = function () {
    var setIsLoading = useContext(LoadingContext).setIsLoading;
    return {
        show: function () { return setIsLoading(true); },
        hide: function () { return setIsLoading(false); },
        set: function (value) { return setIsLoading(value); },
    };
};
export default useLoading;
