var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { WebgateProvider } from "@xvia/webgate-connect";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import "./styles/main.scss";
import { theme } from "./styles/theme";
var baseName = window.__APP_BASE__ || "/";
var root = ReactDOM.createRoot(document.getElementById("root"));
root.render(_jsx(React.StrictMode, { children: _jsx(BrowserRouter, __assign({ basename: baseName }, { children: _jsx(WebgateProvider, __assign({ portalUrl: process.env.PORTAL_URL }, { children: _jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(StyledEngineProvider, __assign({ injectFirst: true }, { children: _jsx(App, {}) })) })) })) })) }));
